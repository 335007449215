<template>
  <div>
    <div class="search-section ms-auto col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <div class="search-section-body">
        <input v-model="search" @input="filterItems" type="text" name="search" id="search" class="search-input"
          placeholder="Müqaviləni axtar"/>
        <img src="@/assets/images/search.svg" alt="search" />
      </div>
    </div>
    <div v-if="isLoading">
      <CardSkeleton />
    </div>
    <div v-else-if="contracts.length > 0">
      <div class="insurance-items">
        <div v-for="(d, index) in filteredItems" :key="index" class="insurance-item">
          <div class="insurance-item-head row">
            <h2 class="insurance-item-head-name p-0 mb-0">{{ d.carNumber }} - {{ d.make }} {{ d.model }}</h2>
            <div class="insurance-item-head-btn" :class="getElementClass(d.status)">
              <span>{{ getStatusDesc(d.status) }}</span>
            </div>
          </div>
          <div class="insurance-item-content">
            <div class="row insurance-item-content-body mt-3">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <div class="check-info-content-body-head">Sığorta olunan</div>
                <div class="insurance-item-content-body-text"> {{ d.fullName }} </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <div class="check-info-content-body-head">Müqavilə nömrəsi</div>
                <div id="policyNumber" class="insurance-item-content-body-text">
                  {{ d.contractNumber }}
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <div class="check-info-content-body-head">Sığorta məbləği</div>
                <div class="insurance-item-content-body-text">{{ d.price }} AZN </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 ">
                  <div class="check-info-content-body-head">Etibarlıdır:</div>
                  <div v-if="d.expiryDate == null" class="insurance-item-content-body-text">
                    Etibarsızdır
                  </div>
                  <div v-else class="insurance-item-content-body-text">
                    {{ dateTime(d.expiryDate) }}
                  </div>
                </div>
            </div>
            <div class="row justify-content-between align-items-center insurance-item-content-body mt-3">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-9 col-xl-9 row">
                  <div class="check-info-content-body-head">Sığorta şirkəti</div>
                  <div class="insurance-item-content-body-text">
                    {{ d.insuranceCompanyName }}
                  </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex justify-content-end">
                <router-link :to="`/profile/contracts/cmtpl/${d.status}/${d.contractNumber}`" class="
                  btn-main btn-default btn-hover
                  cursor-pointer
                  d-inline-block
                  insurance-item-btn
                ">
                  <img src="@/assets/images/info-white.svg" class="me-1" />
                  Ətraflı
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else >
      <Empty/>
    </div>
  </div>
</template>


<script>

import api from "@/api/cmtpl.api";
import cmtplStatues from "@/data/CmtplContractStatuses";
import moment from "moment";
import CardSkeleton from "./CardSkeleton.vue";
import Empty from "./Empty.vue"

export default {
  components: { CardSkeleton,Empty },
  data: () => ({
    isLoading: false,
    contracts: [],
    statuses: cmtplStatues,
    search: "",
    filteredItems: []
  }),
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const { status } = this.$route.params;

      console.log(this.$route.params, "status")

      var request = null;

      this.isLoading = true;


      switch (status) {
        case "active":
          request = api.getActiveContracts();
          break;
        case "archive":
          request = api.getArchiveContracts();
          break;
        default:
          break;
      }

      request
        .then((data) => (this.contracts = data,this.filteredItems = data))
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },
    getStatusDesc(status) {
      return cmtplStatues.find((o) => o.value === status)?.text ?? "...";
    },

    dateTime(value) {
      return moment(value).format("DD.MM.YY");
    },

    getElementClass(status) {
      switch (status) {
        case 1:
          return 'background-yellow';
        case 2:
          return 'background-green';
        case 3:
        case 6:
          return 'background-red';
        case 4:
        case 5:
        case 7:
          return 'background-gray';
        default:
          return '';
      }
    },

    filterItems() {
      const search = this.search.toLowerCase();
      this.filteredItems = this.contracts.filter((item) => {
        return (
          (item.carNumber && item.carNumber.toLowerCase().includes(search)) ||
          (item.make && item.make.toLowerCase().includes(search)) ||
          (item.model && item.model.toLowerCase().includes(search)) ||
          (item.insuranceCompanyName && item.insuranceCompanyName.toLowerCase().includes(search)) ||
          (item.fullName && item.fullName.toLowerCase().includes(search)) 
        );
      });
    },
  },
};
</script>